<template>
  <!-- 作业考试成绩 -->
  <div class="main-contain">
    <el-table :data="tableData">
      <el-table-column prop="name" label="名称"> </el-table-column>
      <el-table-column prop="type" label="类型">
        <template slot-scope="scope">
          {{ scope.row.type|typeformat }}
        </template>
      </el-table-column>
      <el-table-column prop="user_score" label="成绩"> </el-table-column>
    </el-table>
  </div>
</template>

<script>
const typemap=['其他','作业','考试']
import { gettestperiodList } from "@/api/testperiod";
export default {
  name: "learnprogress",
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  filters:{
    typeformat(val){
      return typemap[val]
    }
  },
  data() {
    return {
      icon1: require("@/assets/coursedetails/文件类型-标准图-视频文件.png"),
      tableData: [],
    };
  },
  created() {
    this.gettestperiodList({
      related_id: this.item.id,
      id_type: 1,
    });
  },
  methods: {
    initdata() {},
    gettestperiodList(params) {
      this.tableData=[]
      gettestperiodList(params)
        .then((response) => {
          this.tableData=response.data.data
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped lang="scss">
.main-contain {
  margin-top: 26px;
  background: #ffffff;
  padding: 20px;
}
</style>
